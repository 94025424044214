import type { ImgHTMLAttributes } from "react";
import styled from "styled-components";
import Logo from "./graphics/gjensidige-logo_848.7x210.9.svg";

export const GjensidigeLogo = styled(function GjensidigeLogo({
  width = 292,
  ...props
}: { width?: number } & Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src" | "alt" | "width" | "height"
>) {
  return (
    <img
      {...props}
      src={Logo}
      alt="Gjensidige"
      width={width}
      height={(72.5 / 292) * width}
    />
  );
})``;
